import { updateIntl } from 'react-intl-redux'
import { sendGoogleEvent } from 'services/google_data_layer'
import messages from '../../translations/locales'

export const updateLocale = locale => dispatch => {
  dispatch(
    updateIntl({
      locale,
      messages: messages[locale]
    })
  )

  sendGoogleEvent({
    event: 'setCheckoutLocale',
    checkoutLocale: locale
  })
}
