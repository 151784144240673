import { Translation } from 'react-i18next'
import Api from 'services/api'
import React from 'react'
import * as type from '../constants/action_types/product_alerts'
import { createNotification } from './notifications'

export const getProductAlerts = () => async dispatch => {
  dispatch({
    type: type.PENDING_PRODUCT_ALERTS_ACTION,
    payload: 'productAlertsFetch'
  })

  const res = await Api.getProductAlerts()

  if (res.ok) {
    const alertedProducts = {}

    res.data.forEach(alert => {
      alertedProducts[alert.pipelines_id] = alert
    })

    dispatch({
      type: type.SET_PRODUCT_ALERTS_DATA,
      payload: {
        alertedProducts
      }
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Product Alerts Load Error'
      })
    )
  }

  dispatch({
    type: type.RESET_PRODUCT_ALERTS_ACTION,
    payload: 'productAlertsFetch'
  })
}

export const addProductAlert = (
  product,
  showNotification = true
) => async dispatch => {
  dispatch({
    type: type.PENDING_PRODUCT_ALERTS_ADD_REMOVE_ACTION,
    payload: product.id
  })

  const res = await Api.addProductAlert(product)

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_ADD_PRODUCT_ALERTS_ACTION,
      payload: res.data
    })

    showNotification &&
      dispatch(
        createNotification({
          message: (
            <Translation>
              {t => t('alerts:alertsMessages.added', 'Added to Alerts!')}
            </Translation>
          ),
          level: 'success',
          title: (
            <Translation>
              {t => t('alerts:alertsMessages.successTitle', 'Product Alerts')}
            </Translation>
          )
        })
      )
  } else {
    showNotification &&
      dispatch(
        createNotification({
          message: res.error,
          level: 'error',
          title: (
            <Translation>
              {t => t('alerts:alertsMessages.errTitle', 'Product Alerts Error')}
            </Translation>
          )
        })
      )
  }

  dispatch({
    type: type.RESET_PRODUCT_ALERTS_ADD_REMOVE_ACTION,
    payload: product.id
  })
}

export const addBulkProductAlerts = (
  products,
  showNotification = true
) => async dispatch => {
  const pipelinesIds = products.map(item => item.id)
  dispatch({
    type: type.PENDING_PRODUCT_ALERTS_ADD_REMOVE_BULK_ACTION,
    payload: pipelinesIds
  })

  const res = await Api.addProductAlertsBulk({ product_alerts: products })
  if (res.ok) {
    dispatch({
      type: type.COMPLETED_ADD_PRODUCT_ALERTS_BULK_ACTION,
      payload: res.data
    })

    showNotification &&
      dispatch(
        createNotification({
          message: (
            <Translation>
              {t =>
                t(
                  'alerts:alertsMessages.bulkAdded',
                  'Added Selection to Alerts!'
                )
              }
            </Translation>
          ),
          level: 'success',
          title: (
            <Translation>
              {t => t('alerts:alertsMessages.successTitle', 'Product Alerts')}
            </Translation>
          )
        })
      )
  } else {
    showNotification &&
      dispatch(
        createNotification({
          message: res.error,
          level: 'error',
          title: (
            <Translation>
              {t => t('alerts:alertsMessages.errTitle', 'Product Alerts Error')}
            </Translation>
          )
        })
      )
  }

  dispatch({
    type: type.RESET_PRODUCT_ALERTS_ADD_REMOVE_BULK_ACTION,
    payload: pipelinesIds
  })
}

export const removeBulkProductAlerts = pipelinesIds => async (
  dispatch,
  getState
) => {
  dispatch({
    type: type.PENDING_PRODUCT_ALERTS_ADD_REMOVE_BULK_ACTION,
    payload: pipelinesIds
  })

  const { alertedProducts } = getState().productAlerts
  const productIds = Object.entries(alertedProducts)
    .reduce((acc, [key, alert]) => {
      if (pipelinesIds.includes(key)) {
        acc.push(alert.id)
      }
      return acc
    }, [])
    .map(id => id)

  const res = await Api.removeProductAlertsBulk({ ids: productIds })
  if (res.ok) {
    dispatch({
      type: type.COMPLETED_REMOVE_PRODUCT_ALERTS_ACTION,
      payload: pipelinesIds
    })
    dispatch(
      createNotification({
        message: (
          <Translation>
            {t =>
              t(
                'alerts:alertsMessages.bulk_removed',
                'Removed Selected Products from Alerts'
              )
            }
          </Translation>
        ),
        level: 'success',
        title: (
          <Translation>
            {t => t('alerts:alertsMessages.successTitle', 'Product Alerts')}
          </Translation>
        )
      })
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: (
          <Translation>
            {t => t('alerts:alertsMessages.errTitle', 'Product Alerts Error')}
          </Translation>
        )
      })
    )
  }

  dispatch({
    type: type.RESET_PRODUCT_ALERTS_ADD_REMOVE_BULK_ACTION,
    payload: pipelinesIds
  })
}

export const removeProductAlert = (id, pipelinesId) => async dispatch => {
  dispatch({
    type: type.PENDING_PRODUCT_ALERTS_ADD_REMOVE_ACTION,
    payload: pipelinesId
  })

  const res = await Api.removeProductAlert(id)

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_REMOVE_PRODUCT_ALERTS_ACTION,
      payload: pipelinesId
    })
    dispatch(
      createNotification({
        message: (
          <Translation>
            {t => t('alerts:alertsMessages.removed', 'Removed from Alerts')}
          </Translation>
        ),
        level: 'success',
        title: (
          <Translation>
            {t => t('alerts:alertsMessages.successTitle', 'Product Alerts')}
          </Translation>
        )
      })
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: (
          <Translation>
            {t => t('alerts:alertsMessages.errTitle', 'Product Alerts Error')}
          </Translation>
        )
      })
    )
  }

  dispatch({
    type: type.RESET_PRODUCT_ALERTS_ADD_REMOVE_ACTION,
    payload: pipelinesId
  })
}

export const checkBatchOperationComplete = (
  successMessage,
  errorMessage,
  batchSize,
  showNotification = true
) => (dispatch, getState) => {
  const { batchOperationStatus } = getState().productAlerts

  if (batchSize === batchOperationStatus.length) {
    const isSuccessful = batchOperationStatus.every(
      entry => entry === 'success'
    )

    if (showNotification) {
      if (isSuccessful) {
        dispatch(
          createNotification({
            message: successMessage,
            level: 'success',
            title: (
              <Translation>
                {t => t('alerts:alertsMessages.successTitle', 'Product Alerts')}
              </Translation>
            )
          })
        )
      } else {
        dispatch(
          createNotification({
            message: errorMessage,
            level: 'error',
            title: (
              <Translation>
                {t =>
                  t('alerts:alertsMessages.errTitle', 'Product Alerts Error')
                }
              </Translation>
            )
          })
        )
      }
    }

    dispatch({
      type: type.SET_PRODUCT_ALERTS_DATA,
      payload: {
        batchOperationStatus: []
      }
    })
  }
}

export const addProductAlertInBatch = (
  product,
  batchSize = 1,
  showNotification = true
) => async (dispatch, getState) => {
  dispatch({
    type: type.PENDING_PRODUCT_ALERTS_ADD_REMOVE_ACTION,
    payload: product.id
  })

  const res = await Api.addProductAlert(product)

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_ADD_PRODUCT_ALERTS_ACTION,
      payload: res.data
    })

    dispatch({
      type: type.SET_PRODUCT_ALERTS_DATA,
      payload: {
        batchOperationStatus: [
          ...getState().productAlerts.batchOperationStatus,
          'success'
        ]
      }
    })
  } else {
    dispatch({
      type: type.SET_PRODUCT_ALERTS_DATA,
      payload: {
        batchOperationStatus: [
          ...getState().productAlerts.batchOperationStatus,
          'error'
        ]
      }
    })
  }

  dispatch(
    checkBatchOperationComplete(
      <Translation>
        {t =>
          t(
            'alerts:alertsMessages.successfullyAddedAll',
            'Added all Products to Alerts!'
          )
        }
      </Translation>,
      <Translation>
        {t =>
          t(
            'alerts:alertsMessages.failedAddedAll',
            'Failed to add some products to Alerts'
          )
        }
      </Translation>,
      batchSize,
      showNotification
    )
  )

  dispatch({
    type: type.RESET_PRODUCT_ALERTS_ADD_REMOVE_ACTION,
    payload: product.id
  })
}

export const removeProductAlertInBatch = (
  id,
  pipelinesId,
  batchSize = 1
) => async (dispatch, getState) => {
  dispatch({
    type: type.PENDING_PRODUCT_ALERTS_ADD_REMOVE_ACTION,
    payload: pipelinesId
  })

  const res = await Api.removeProductAlert(id)

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_REMOVE_PRODUCT_ALERTS_ACTION,
      payload: pipelinesId
    })

    dispatch({
      type: type.SET_PRODUCT_ALERTS_DATA,
      payload: {
        batchOperationStatus: [
          ...getState().productAlerts.batchOperationStatus,
          'success'
        ]
      }
    })
  } else {
    dispatch({
      type: type.SET_PRODUCT_ALERTS_DATA,
      payload: {
        batchOperationStatus: [
          ...getState().productAlerts.batchOperationStatus,
          'error'
        ]
      }
    })
  }

  dispatch(
    checkBatchOperationComplete(
      'Removed all Products from Alerts',
      'Failed to remove some Products from Alerts',
      batchSize
    )
  )

  dispatch({
    type: type.RESET_PRODUCT_ALERTS_ADD_REMOVE_ACTION,
    payload: pipelinesId
  })
}

export const batchAddProductAlerts = (products, showNotification = true) => (
  dispatch,
  getState
) => {
  const { alertedProducts } = getState().productAlerts

  const filteredProducts = products.filter(product => {
    const alertedProduct = alertedProducts[product.id] || {}
    return !alertedProduct.id
  })

  for (const product of filteredProducts) {
    dispatch(
      addProductAlertInBatch(product, filteredProducts.length, showNotification)
    )
  }
}

export const batchRemoveProductAlerts = products => (dispatch, getState) => {
  const { alertedProducts } = getState().productAlerts

  const filteredProducts = products.filter(product => {
    const alertedProduct = alertedProducts[product.id] || {}
    return Boolean(alertedProduct.id)
  })

  for (const product of filteredProducts) {
    dispatch(
      removeProductAlertInBatch(
        alertedProducts[product.id].id,
        product.id,
        filteredProducts.length
      )
    )
  }
}
