import { isValidElement } from 'react'

import { trackPendoEvent } from 'services/pendo'
import { sendSegmentErrorMsgShownEvent } from 'helpers/segment'
import { SET_NOTIFICATION } from 'constants/action_types'
import { logException } from '../config/sentry'

const translationDecoder = (_key, english) => english

function decodeTranslation(value) {
  if (typeof value === 'function') {
    return value(translationDecoder)
  }
  return null
}

function extractNotificationText(item) {
  // For when we are passing plain text
  if (typeof item === 'string') {
    return item
  }
  // For when we are passing an array of messages
  if (Array.isArray(item)) {
    return item.join(', ')
  }
  // For all the translation options we use
  if (isValidElement(item)) {
    /*
      Note: before, we used renderToStaticMarkup
        however that was crashing in multiple scenarios
        when used with translations.
        For this reason, I (Armando) just added a more
        specialized version of the code that deals
        with translations
    */
    const {
      children,
      props: { children: propsChildren }
    } = item

    // When the <Translation> component is used
    // First we try to decode using _children_
    let decoded = decodeTranslation(children)
    if (!decoded) {
      // In some cases, using _children_ is not enough
      //  so we check _children_ inside _props_
      decoded = decodeTranslation(propsChildren)
    }
    if (decoded) {
      return decoded
    }
    // If none of the validations above return anything,
    // then we use the default elements
    // When the <Trans> component is used
    return children || propsChildren
  }
  return null
}

// Create a notification res to an event inside the app
// Pass in any arguments to notificationOptions hash when calling this function
// See options at https://github.com/igorprado/react-notification-system
const isNotificationRelatedToPermission = options => {
  return options?.message?.type === 'Gated Feature'
}

export const createNotification = notificationOptions => (
  dispatch,
  getState
) => {
  if (isNotificationRelatedToPermission(notificationOptions)) {
    return null
  }

  const { message, level, title } = notificationOptions || {}
  const { appType } = getState().globalData || 'default'

  if (level === 'error') {
    let customTitle = 'Error'
    let customMessage = 'General Error'
    try {
      customTitle = extractNotificationText(title)
      customMessage = extractNotificationText(message)
    } catch (e) {
      logException(e)
    }

    try {
      const pendoParams = { title: customTitle, message: customMessage }
      const segmentParams = { errorTitle: customTitle, message: customMessage }
      trackPendoEvent('Error Notification Displayed', pendoParams)
      sendSegmentErrorMsgShownEvent(appType, segmentParams)
    } catch (e) {
      logException(e)
    }
  }
  dispatch({
    type: SET_NOTIFICATION,
    data: notificationOptions
  })
}
