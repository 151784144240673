import { stopImpersonatingUser as stopImpersonatingUserApi } from 'services/api/impersonation'
import * as Cookies from 'services/cookies'
import { queryClient } from 'services/query_client'

import { getAmazonSellerAccounts } from './amazon_seller_accounts'
import { createNotification } from './notifications'
import { loadGlobalData, setGlobalModal } from './index'
import { loadChecklistData } from './checklist'
import { resetPagesData } from './reset'

export const stopImpersonatingUser = () => async (dispatch, getState) => {
  const oldEmail = getState()?.globalData?.impersonatingUser

  const res = await stopImpersonatingUserApi()

  if (res.ok) {
    Cookies.saveAuthToken(res.data.access_token)

    await queryClient.cancelQueries()
    await queryClient.cancelMutations()

    queryClient.clear()

    dispatch(getAmazonSellerAccounts())
    await dispatch(loadGlobalData())
    await dispatch(loadChecklistData())
    dispatch(setGlobalModal(null))

    resetPagesData(dispatch)

    dispatch(
      createNotification({
        message: res.message,
        level: 'info',
        title: `Stopped Impersonating: ${oldEmail}`
      })
    )

    window.location.hash = res.data.route
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'info',
        title: `Error Stopping Impersonating: ${oldEmail}`
      })
    )
  }
}
