import React from 'react'
import { Translation } from 'react-i18next'

import { getSelectedAmazonSellerAccount } from 'helpers/amazon_seller_account'
import * as type from 'constants/action_types/product'
import Api from 'services/api'

import { setSentryMWSData } from '../config/sentry'
import { createNotification } from './notifications'
import { selectAmazonSellerAccount } from '.'

export const removeGetResyncStatusInterVal = () => dispatch => {
  dispatch({
    type: type.UPDATE_GET_RESYNC_STATUS_TIMER_ACTION,
    payload: {
      timer: null
    }
  })
}

export const getProductsResyncStatus = resyncingProductItems => async (
  dispatch,
  getState
) => {
  const state = getState()
  const {
    amazon_seller_account_id,
    marketplace_id
  } = getSelectedAmazonSellerAccount(state)
  const params = {
    amazon_seller_account_id,
    marketplace_id,
    product_ids: resyncingProductItems.map(item => item.id)
  }

  const response = await Api.getProductsResyncStatus(params)

  if (response.error) {
    dispatch(
      createNotification({
        message: response.error,
        level: 'error',
        title: (
          <Translation ns="myProducts">
            {t =>
              t(
                'myProducts:ProductSettings.notification.title.getSyncStatusError',
                'Get Product Sync Status Error'
              )
            }
          </Translation>
        )
      })
    )
    dispatch(removeGetResyncStatusInterVal())
    return
  }

  const resyncedData = response.data.filter(item => item.sync_status !== 1)

  dispatch({
    type: type.UPDATE_PRODUCTS_RESYNC_STATUS_ACTION,
    payload: {
      updateData: resyncedData
    }
  })

  if (resyncedData.length) {
    // eslint-disable-next-line no-use-before-define
    dispatch(checkIfResyncProductsExist())
  }
}

export const checkIfResyncProductsExist = () => (dispatch, getState) => {
  const {
    product: { productItems }
  } = getState()
  const resyncingProductItems = productItems.reduce((pre, cur) => {
    const { children = [], sync_status } = cur
    const res = (sync_status === 1 ? [cur] : []).concat(
      cur.isExpanded ? children.filter(child => child.sync_status === 1) : []
    )
    return pre.concat(res)
  }, [])

  if (!resyncingProductItems.length) {
    dispatch(removeGetResyncStatusInterVal())
    return
  }

  const getResyncStatusInterval = setInterval(() => {
    dispatch(getProductsResyncStatus(resyncingProductItems))
  }, 30000)

  dispatch({
    type: type.UPDATE_GET_RESYNC_STATUS_TIMER_ACTION,
    payload: {
      timer: getResyncStatusInterval
    }
  })
}

export const updateProductSearchTerm = value => dispatch => {
  dispatch({
    type: type.UPDATE_PRODUCT_SEARCH_TERM,
    payload: value
  })
}

/*
For reference: https://github.com/Junglescout/junglescout_api/wiki/Product-Settings
*/

export const loadProducts = ({
  search = '',
  perPage = 20,
  page = null,
  goingForward = true,
  resetPagination = false,
  orderBy = null, // null defaults to toggle status according to Jake
  orderDir = null,
  keepPagination = false,
  initialLoad = false,
  preserveList = false,
  enabled = true,
  editId = null
}) => async (dispatch, getState) => {
  if (search?.length) {
    dispatch(
      createNotification({
        message: (
          <Translation ns="myProducts">
            {t =>
              t(
                'myProducts:ProductSettings.notification.message.search',
                'Search is limited to one ASIN at a time.'
              )
            }
          </Translation>
        ),
        level: 'success',
        title: (
          <Translation ns="myProducts">
            {t =>
              t(
                'myProducts:ProductSettings.notification.title.search',
                'Individual ASIN Search'
              )
            }
          </Translation>
        )
      })
    )
  }

  dispatch({
    type: type.PRODUCT_PENDING_ACTION,
    payload: initialLoad ? 'isLoading' : 'isLoadingTable'
  })

  if (!orderDir) {
    orderDir = getState().product.orderDir
  } else if (orderDir !== getState().product.orderDir) {
    page = 0
    keepPagination = false
  }

  if (!orderBy) {
    orderBy = getState().product.orderBy
  } else {
    // Toggle orderDir
    if (orderBy === getState().product.orderBy) {
      orderDir = orderDir === 'asc' ? 'desc' : 'asc'
    }
    page = 0
    keepPagination = false
  }

  page = page < 0 ? null : page

  const state = getState()
  const {
    amazon_seller_account_id,
    marketplace_id
  } = getSelectedAmazonSellerAccount(state)

  const areAmazonParamsEqual =
    state.product.amazonParams.amazon_seller_account_id ===
      amazon_seller_account_id &&
    state.product.amazonParams.marketplace_id === marketplace_id

  // if we're loading from a different account - reset the pagination or if this is page 0/null
  resetPagination = resetPagination || !areAmazonParamsEqual || !page

  const response = await Api.loadProducts({
    amazon_seller_account_id,
    marketplace_id,
    search,
    page,
    perPage,
    orderBy,
    orderDir,
    enabled
  })

  if (response.error) {
    dispatch(
      createNotification({
        message: response.error,
        level: 'error',
        title: 'Product Error'
      })
    )
  } else {
    dispatch({
      type: type.COMPLETED_LOAD_PRODUCTS,
      payload: {
        ...response.data,
        orderBy,
        orderDir,
        preserveList
      }
    })

    if (!keepPagination) {
      dispatch({
        type: resetPagination
          ? type.PRODUCT_RESET_PAGINATION_DETAILS
          : type.PRODUCT_UPDATE_PAGINATION_DETAILS,
        payload: {
          ...response.data,
          goingForward,
          amazonParams: { amazon_seller_account_id, marketplace_id }
        }
      })
    }

    // this happens if the user is directed to this page from another page e.g.
    // inventory manager. We set the ASIN as the search param so we ensure the product is found
    // and the edit ID so we enable editing to save them having to click the edit icon
    if (editId) {
      dispatch(enableProductEditing(editId))
      dispatch(updateProductSearchTerm(search || ''))
    }
  }

  dispatch({
    type: type.PRODUCT_COMPLETED_ACTION,
    payload: initialLoad ? 'isLoading' : 'isLoadingTable'
  })
  dispatch(checkIfResyncProductsExist())
}

export const enableProductEditing = (id, parentId = null) => dispatch => {
  if (parentId) {
    dispatch({
      type: type.ENABLE_PRODUCT_CHILD_EDITABLE_FIELD,
      payload: { parentId, childId: id }
    })
  } else {
    dispatch({
      type: type.ENABLE_PARENT_VARIANT_EDITABLE_FIELD,
      payload: id
    })
  }
}

export const selectAmazonSellerAccountCountry = country => dispatch => {
  dispatch({
    type: type.SET_AMAZON_SELLER_ACCOUNT_SELECTED_COUNTRY,
    payload: country
  })

  setSentryMWSData({ marketplace_id: country?.marketplace_id })
}

export const viewProductInMyProducts = (country, asin, amazonAccountId) => (
  dispatch,
  getState
) => {
  const state = getState()
  const {
    amazonSellerAccounts: { amazonSellerAccounts }
  } = state

  const {
    selectedAccount,
    amazon_seller_account_id,
    country_code
  } = getSelectedAmazonSellerAccount(state)

  if (
    Boolean(amazonAccountId) &&
    amazon_seller_account_id !== amazonAccountId
  ) {
    const account = amazonSellerAccounts.find(
      accountItem => accountItem.id === amazonAccountId
    )

    const countryObject = account?.countries?.find(
      entry => entry.country_code.toLowerCase() === country
    )

    if (account && countryObject) {
      dispatch(selectAmazonSellerAccount({ account, country: countryObject }))

      dispatch(
        createNotification({
          message: 'Updated current Account',
          level: 'info',
          title: 'Amazon Seller Account'
        })
      )
    }
  } else if (country_code !== country) {
    const countryObject = selectedAccount?.countries?.find(
      entry => entry.country_code.toLowerCase() === country
    )

    if (countryObject) {
      dispatch(selectAmazonSellerAccountCountry(countryObject))

      dispatch(
        createNotification({
          message: 'Updated current Marketplace',
          level: 'info',
          title: 'Amazon Seller Account'
        })
      )
    }
  }

  dispatch(updateProductSearchTerm(asin))

  window.location.hash = 'product-costs-and-settings'
}
